/** 
* Pinned Page Navbar for Squarespace
* Copyright Will-Myers.com
***/ 

[data-wm-plugin="pinned-page-navbar"] {
  /*Nav Container*/
  --ppn-padding-x: 8px;  /*internal padding of nav*/
  --ppn-padding-y: 8px;  /*internal padding of nav*/
  --ppn-border-radius: 2em;   /*internal padding of nav*/
  --ppn-background: var(--primaryButtonBackgroundColor);
  --ppn-border-style: solid;
  --ppn-border-width: 0px;
  --ppn-border-color: hsla(var(--safeLightAccent-hsl));
  --ppn-shadow-offset-x: 2px;
  --ppn-shadow-offset-y: 2px;
  --ppn-shadow-blur: 4px;
  --ppn-shadow-spread: 0px;
  --ppn-shadow-opacity: 0.15;
  --ppn-shadow-color: hsla(0, 0%, 0%, var(--ppn-shadow-opacity));
  --ppn-max-width: 88vw;
  --ppn-bottom-offset: 34px;
  --ppn-background-blur: 0px;

  /*Button Styles*/
  --ppn-button-border-radius: 2em;   /*internal padding of nav*/
  --ppn-button-font-size: calc((var(--ppn-font-size, var(--primary-button-font-font-size-value, 1)) - 1) * 1.2vw + 1rem) ;
  --ppn-button-color: var(--siteBackgroundColor);
  --ppn-button-font-family: var(--primary-button-font-font-family);
  --ppn-button-font-style: var(--primary-button-font-font-style);
  --ppn-button-font-weight: var(--primary-button-font-font-weight);
  --ppn-button-line-height: var(--primary-button-font-line-height);
  --ppn-button-letter-spacing: var(--primary-button-font-letter-spacing);
  --ppn-button-text-transform: var(--primary-button-font-text-transform);
  --ppn-button-padding-y: calc(var(--primary-button-padding-y) / 2);
  --ppn-button-padding-x: calc(var(--primary-button-padding-x) / 2);
  --ppn-button-background: hsla(var(--darkAccent-hsl));
  --ppn-button-background: transparent;
  --ppn-button-border-style: solid;
  --ppn-button-border-width: 0px;
  --ppn-button-border-color: currentColor;
  --ppn-button-spacing: 8px;

  /*Active Button Styles*/
  --ppn-active-button-color: var(--primaryButtonBackgroundColor);
  --ppn-active-button-background: 0;
  --ppn-active-button-border-color: hsla(var(--safeLightAccent-hsl));

  /*Indicator Styles*/
  --ppn-indicator-background: var(--siteBackgroundColor);
  --ppn-indicator-border-style: solid;
  --ppn-indicator-border-width: 0px;
  --ppn-indicator-border-color: var(--primaryButtonBackgroundColor);
  --ppn-indicator-border-radius: 2rem;
  --ppn-transition-speed: 0.5s;

  /* Scroll Indicators */
  --ppn-scroll-indicator-padding: 4px;
  --ppn-scroll-indicator-background: var(--primaryButtonBackgroundColor);
  --ppn-scroll-indicator-color: var(--siteBackgroundColor);
  --ppn-scroll-indicator-size: 30px;
  --ppn-scroll-indicator-offset: 15px;
  --ppn-scroll-indicator-border-style: solid;
  --ppn-scroll-indicator-border-width: 1px;
  --ppn-scroll-indicator-border-color: currentColor;
  --ppn-scroll-indicator-background-blur: 0px;
}

.wm-pinned-page-navbar {
  position: fixed;
  bottom: var(--ppn-bottom-offset);
  left: 50%;
  z-index: 9999;
  line-height: 0;
  /*overflow:hidden;*/
  transform: translate(-50%, 250px);
  transition: transform var(--ppn-transition-speed) ease var(--ppn-transition-speed);
}

.wm-pinned-page-navbar .nav-container {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: var(--ppn-border-radius);
  background: var(--ppn-background);
  border-style: var(--ppn-border-style);
  border-width: var(--ppn-border-width);
  border-color: var(--ppn-border-color);
  box-shadow: var(--ppn-shadow-offset-x) 
    var(--ppn-shadow-offset-y) 
    var(--ppn-shadow-blur) 
    var(--ppn-shadow-spread) 
    var(--ppn-shadow-color);
  backdrop-filter: blur(var(--ppn-background-blur));
  -webkit-backdrop-filter: blur(var(--ppn-background-blur));
}
.wm-pinned-page-navbar nav {
  padding: var(--ppn-padding-y) var(--ppn-padding-x);
  display: inline-flex;
  position: relative;
  flex-wrap: nowrap;
  overflow: auto;
  width: 100%;
  max-width: var(--ppn-max-width);
  box-sizing: border-box;
}

.wm-pinned-page-navbar button {
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: var(--ppn-button-spacing);
  cursor: pointer;
  border-radius: var(--ppn-button-border-radius);
  padding: var(--ppn-button-padding-y) var(--ppn-button-padding-x);
  color: var(--ppn-button-color);
  background: var(--ppn-button-background);
  border-style: var(--ppn-button-border-style);
  border-width: var(--ppn-button-border-width);
  border-color: var(--ppn-button-border-color);
  z-index: 1;
  transition: color var(--ppn-transition-speed) ease;
  white-space: nowrap;
  font-size: var(--ppn-button-font-size);
  font-family: var(--ppn-button-font-family);
  font-style: var(--ppn-button-font-style);
  font-weight: var(--ppn-button-font-weight);
  letter-spacing: var(--ppn-button-letter-spacing);
  text-transform: var(--ppn-button-text-transform);
}

.wm-pinned-page-navbar button:last-child {
  margin-right: 0px;
}

.wm-pinned-page-navbar button.active {
  color: var(--ppn-active-button-color);
  background: var(--ppn-active-button-background);
  border-color: var(--ppn-active-button-border-color);
}

.wm-pinned-page-navbar .indicator-track {
  position: absolute;
  overflow: hidden;
  width: var(--nav-width,100%);
  height: 100%;
  box-sizing:border-box;
  padding: var(--ppn-padding-y) var(--ppn-padding-x);
  left: 0;
  top: 0;
  z-index: 0;
}
.wm-pinned-page-navbar .indicator {
  width: var(--indicator-width, 0px);
  height: var(--indicator-height, calc(100% - 8px));
  position: absolute;
  left: var(--indicator-left, -200px);
  top: var(--ppn-padding-y);
  background: var(--ppn-indicator-background);
  box-sizing: border-box;
  border-radius: var(--ppn-border-radius, var(--ppn-indicator-border-radius));
  border-style: var(--ppn-indicator-border-style);
  border-width: var(--ppn-indicator-border-width);
  border-color: var(--ppn-indicator-border-color);
  transition: left var(--ppn-transition-speed) ease, width var(--ppn-transition-speed) ease, height var(--ppn-transition-speed) linear, transform var(--ppn-transition-speed) ease, opacity var(--ppn-transition-speed) ease, visibility var(--ppn-transition-speed) ease;
  z-index: 0;
}
.wm-pinned-page-navbar .indicator.hidden {
  opacity: 0;
}

.wm-pinned-page-navbar .scroll-indicator{
  position:absolute;
  top:50%;
  transform: translateY(-50%) translateX(0);
  border-radius: 50%;
  opacity: 1;
  visibility: visible;
  border-style: var(--ppn-scroll-indicator-border-style);
  border-width: var(--ppn-scroll-indicator-border-width);
  border-color: var(--ppn-scroll-indicator-border-color);
  backdrop-filter: blur(var(--ppn-scroll-indicator-background-blur));
  padding: var(--ppn-scroll-indicator-padding);
  background: var(--ppn-scroll-indicator-background);
  color: var(--ppn-scroll-indicator-color);
  box-sizing: border-box;
  display: grid;
  place-items: center;
  height: var(--ppn-scroll-indicator-size);
  width: var(--ppn-scroll-indicator-size);
  z-index: 2;
  transition: opacity 0.5s ease,
    transform 0.5s ease,
    visibility 0.5s ease;
}
.wm-pinned-page-navbar  .scroll-indicator svg {
  height:100%;
  width:100%;
}
.wm-pinned-page-navbar  .scroll-indicator.scroll-left{
  left: calc(-1 * var(--ppn-scroll-indicator-offset));
}
.wm-pinned-page-navbar  .scroll-indicator.scroll-right{
  right: calc(-1 * var(--ppn-scroll-indicator-offset));
}

/*Scroll Indicator States*/
[data-scroll-indicator="start"].wm-pinned-page-navbar .scroll-left{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%) translateX(-10px);
  transition: opacity 0.5s ease,
    transform 0.5s ease,
    visibility 0.5s ease 0.5s ;
}

[data-scroll-indicator="end"].wm-pinned-page-navbar  .scroll-right{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%) translateX(10px);
  transition: opacity 0.5s ease,
    transform 0.5s ease,
    visibility 0.5s ease 0.5s;
}
[data-scroll-indicator="no-scroll"].wm-pinned-page-navbar .scroll-left,
[data-scroll-indicator="no-scroll"].wm-pinned-page-navbar .scroll-right{
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease,
    transform 0.5s ease,
    visibility 0.5s ease 0.5s ;
}


/* Visible */
.wm-pinned-page-navbar.show, 
.wm-pinned-page-navbar.pin-to-top.show {
  transform: translate(-50%, 0px);
}

/*Hide Scrollbars*/
.wm-pinned-page-navbar nav::-webkit-scrollbar {
  display: none;
}
.wm-pinned-page-navbar nav {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

/*In Edit Mode*/
[data-wm-pinned-page-navbar], [data-wm-pinned-page-navbar-end] {
  position: absolute;
}
body:not(.sqs-edit-mode-active) [data-wm-pinned-page-navbar],
body:not(.sqs-edit-mode-active) [data-wm-pinned-page-navbar-end] {
  display:none;
}

[data-scroll-position="above-first"].wm-pinned-page-navbar .indicator {
  transform: translateX(calc(-1 * var(--ppn-padding-x) - 100%))
}
[data-scroll-position="below-last"].wm-pinned-page-navbar .indicator {
  transform: translateX(calc(var(--ppn-padding-x) + 100%))
}


/*Pin to Top*/
div.wm-pinned-page-navbar.pin-to-top {
  top: calc(var(--ppn-bottom-offset, ) + var(--header-bottom, 0px));
  bottom: unset !important;
  transform: translate(-50%, -250px);
  transition: top 0.3s ease,transform var(--ppn-transition-speed) ease var(--ppn-transition-speed);
}
